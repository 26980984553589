<template>
  <div v-if="lawyerProfile">
    <div class="row">
      <div class="col-md-8 bottom-30">
        <lawyer-bio
          :lawyer-profile="lawyerProfile"
          :viewing-as-client="viewingAsClient"
          :on-edit-profile="editProfile"
          :on-upload-resume="uploadResume">
        </lawyer-bio>
      </div>

      <div class="col-md-4 bottom-30">
        <lawyer-profile-sidebar
          :lawyer-profile="lawyerProfile"
          :possible-lawyer="possibleLawyer"
          :base-rate-dollars="baseRateDollars"
          :is-lawyer-account="isLawyerAccount"
          :viewing-as-client="viewingAsClient"
          :on-edit-profile="editProfile"
          :on-save-rate="saveRate">
        </lawyer-profile-sidebar>
      </div>
    </div>

    <lawyer-experience
      :lawyer-profile="lawyerProfile"
      :lawyer="lawyer"
      :viewing-as-client="viewingAsClient"
      :current-user-is-lawyer="currentUserIsLawyer"
      :on-edit-profile="editProfile">
    </lawyer-experience>

    <lawyer-testimonials
      :testimonials="testimonials"
      :viewing-as-client="viewingAsClient"
      class="top-30"
      v-if="hasTestimonials || isEditingOwnProfile">
    </lawyer-testimonials>

    <modal-wrapper name="indicativeRate" modal-size="lg">
      <template #default="{ modalData: modalData, closeModal: closeModal }">
        <indicative-rate-modal-content
          ref="indicativeRateModalContent"
          :lawyer-rate-cents="modalData.indicativeRate"
          :on-cancel="closeModal"
          :on-save-rate="modalData.onSaveRate">
        </indicative-rate-modal-content>
      </template>
    </modal-wrapper>
  </div>
</template>

<script>
import LawyerBio from './lawyer-bio.vue';
import LawyerProfileSidebar from './lawyer-profile-sidebar.vue';
import LawyerExperience from './lawyer-experience.vue';
import LawyerTestimonials from './lawyer-testimonials.vue';
import ModalWrapper from 'vue-app/shared/components/modals/wrapper.vue';
import IndicativeRateModalContent from './indicative-rate-modal-content.vue';
import ModalManager from 'vue-app/shared/services/modal-manager.js';

export default {
  name: 'LawyerProfile',

  components: {
    LawyerBio,
    LawyerProfileSidebar,
    LawyerExperience,
    LawyerTestimonials,
    ModalWrapper,
    IndicativeRateModalContent
  },

  props: {
    lawyerProfile: {
      type: Object,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    possibleLawyer: {
      type: Object,
      required: false
    },

    viewingAsClient: {
      type: Boolean,
      required: true
    },

    currentUserIsLawyer: {
      type: Boolean,
      default: false
    },

    initEditRate: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    baseRateDollars() {
      return (this.lawyer.clientRate || 0) / 100;
    },

    isLawyerAccount() {
      return this.lawyer.accountType === 'lawyer';
    },

    testimonials() {
      return this.lawyer.reviews || [];
    },

    hasTestimonials() {
      return this.testimonials.length > 0;
    },

    isEditingOwnProfile() {
      return !this.viewingAsClient && !this.lawyer.adminEditing;
    }
  },

  mounted() {
    if (this.initEditRate && !this.viewingAsClient) {
      this.openRateModal();
    }
  },

  methods: {
    editProfile(_templateName) {
      // TODO: this needs to be built. I'm currently migrating the client side
      // view of the profile so it doesn't need editing but we should prioritize
      // building this to prevent the possibility of things diverging.
      // Leaving the Angular code here for reference until we build the Vue version.

      // ctrl.managementFeePercent = ConfigService.defaultManagementFeePercent;
      // ctrl.managementFeeMultiplier = (1.0 + (ctrl.managementFeePercent / 100));

      // var editingModal = $uibModal.open({
      //   size: 'lg',
      //   backdrop: 'static',
      //   component: 'lawyerProfileEditor',
      //   resolve: {
      //     profile: function () {
      //       return ctrl.profile;
      //     },
      //     profileRate: function () {
      //       return {
      //         lawyerAmount: ctrl.profile.indicativeRate / 100,
      //         clientRate: round(ctrl.profile.indicativeRate * ctrl.managementFeeMultiplier) / 100,
      //         editFn: ctrl.openRateModal
      //       };
      //     },
      //     templateName: function () {
      //       return templateName;
      //     }
      //   }
      // });

      // editingModal.result.catch(angular.noop).then(function (updatedProfile) {
      //   if (!updatedProfile) { return; }

      //   NotificationService.clear();

      //   ctrl.updateProfile(updatedProfile);
      // });
    },

    openRateModal() {
      ModalManager.open('indicativeRate', {
        indicativeRate: this.lawyerProfile.indicativeRate || 0,
        onSaveRate: this.saveRate
      });
    },

    saveRate(rate) {
      if (rate.lawyerAmount !== this.lawyerProfile.indicativeRate) {
        // NotificationService.clear();
        this.updateProfile({ indicativeRate: rate.lawyerAmount }, rate);
      }

      ModalManager.close('indicativeRate');
    },

    uploadResume(file) {
      // TODO: move up to parent controllers
      return this.lawyerProfile.uploadResume(file).then((response) => {
        this.lawyerProfile.resume = response.data.resume;
      });
    },

    updateProfile(_updatedProfile, _rate) {
      // TODO: build this (see comment above)
      // Leaving the Angular code here for reference until we build the Vue version.

      // LawyerProfile.update({ id: ctrl.profile.id, lawyerProfile: updatedProfile },
      //   function (result) {
      //     ctrl.profile = result;
      //     ctrl.employmentRecords = ctrl.profile.employmentHistoriesAttributes || [];

      //     if (rate) {
      //       ctrl.baseRateDollars = rate.clientRateDollars;
      //     }
      //   },
      //   function (result) {
      //     if (result.data.errors) {
      //       NotificationService.error('There was a problem saving your profile: ' + result.data.errors.join('; '));
      //     }
      //     else {
      //       NotificationService.error('There was a problem saving your profile.');
      //     }
      //   }
      // );
    }
  }
};
</script>
