<template>
  <div v-if="modalOpen">
    <modal :id="modalId" v-model="modalOpen" :size="modalSize" :header="false" :footer="false" :backdrop="backdrop" :keyboard="backdrop" :before-close="dismissModal" append-to-body>
      <div class="close-modal full-screen" data-toggle="modal" :data-target="modalId" @click="dismissModal">
        <svg-icon name="x3" class="base-icon"></svg-icon>
      </div>

      <slot :modal-data="modalData" :close-modal="dismissModal"></slot>
    </modal>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

import ModalManager from 'vue-app/shared/services/modal-manager.js';

export default {
  name: 'ModalWrapper',

  props: {
    name: {
      type:     String,
      required: true
    },

    modalSize: {
      type:    String,
      default: 'md-sm'
    },

    backdrop: {
      type:    Boolean,
      default: true
    }
  },

  data() {
    return {
      modalOpen: ModalManager.isOpen(this.name),
      modalData: {}
    };
  },

  computed: {
    modalId() {
      return uniqueId(this.name + '-modal-');
    }
  },

  // need listeners since uiv modal requires a mutable property for
  // v-model. If it used events instead, this would work with
  // a simple computed function for `ModalManager.isOpen(this.name)`
  mounted() {
    ModalManager.onOpen(this.name, (modalData) => {
      this.modalData = modalData;
      this.modalOpen = true;
    });

    ModalManager.onClose(this.name, () => {
      this.modalOpen = false;
    });
  },

  methods: {
    dismissModal() {
      ModalManager.close(this.name);
    }
  }
};
</script>
