<template>
  <div class="tag-list">
    <div :class="['tag-list-item', colorClass]" v-for="item in listables" :key="item.id">
      {{ item[valueKey] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagList',

  props: {
    listables: {
      type: Array,
      required: true
    },

    valueKey: {
      type: String,
      default: 'name'
    },

    colorClass: {
      type: String,
      required: false
    }
  }
};
</script>
