<template>
  <priori-modal ref="modal" modal-id="courts-modal" modal-size="md" title="Court Admissions">
    <template #modal-trigger="{ openModal }">
      <span class="normal-weight"><a href="" class="darker-gray-text" @click.prevent="openModal">(Courts)</a></span>
    </template>

    <div class="list-spacing-20" v-for="court in courtAdmissions" :key="court.courtId">
      <span class="normal-weight">{{ nameFor(court) }},</span> {{ admitDateFor(court) }}
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SharedLists from 'vue-app/shared/services/shared-lists-service';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'CourtAdmissionsModal',

  components: {
    PrioriModal
  },

  props: {
    courtAdmissions: {
      type: Array,
      required: true
    }
  },

  methods: {
    nameFor(court) {
      return SharedLists.courtName(court.courtId);
    },

    admitDateFor(court) {
      return DateFilter.year(court.admitDate);
    }
  }
};
</script>

<style lang="scss" scoped>
  section.uiv {
    display: inline;
  }
</style>
