<template>
  <div>
    <div class="vertical-center" v-if="hasResume && !uploadInProgress">
      <span class="right-20">{{ resume.name }}</span>

      <file-selector accept=".pdf" :max-size="50" @change="uploadResume">
        <span class="pseudo-link-blue">Replace File</span>
      </file-selector>
    </div>

    <div v-if="!hasResume && !uploadInProgress">
      <file-selector accept=".pdf" :max-size="50" @change="uploadResume">
        <span class="pseudo-link-blue">Upload File</span>
      </file-selector>
    </div>

    <div class="dark-gray-text" v-if="uploadInProgress">
      Uploading...
    </div>

    <div class="red-text semibold-weight top-10" v-if="uploadError">
      * {{ uploadError }}
    </div>
  </div>
</template>

<script>
import FileSelector from 'vue-app/shared/components/file-selector.vue';

export default {
  name: 'ResumeUploader',

  components: {
    FileSelector
  },

  props: {
    resume: {
      type: Object,
      required: false
    },

    onUploadResume: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      uploadInProgress: false,
      uploadError: null
    };
  },

  computed: {
    hasResume() {
      return this.resume && this.resume.name;
    }
  },

  methods: {
    uploadResume(files) {
      const file = files[0];

      this.uploadInProgress = false;
      this.uploadError = null;

      if (file) {
        this.uploadInProgress = true;

        this.onUploadResume(file)
          .catch(() => {
            this.uploadError = 'There was a problem uploading your resume. Please try again.';
          })
          .finally(() => {
            this.uploadInProgress = false;
          });
      }
      else {
        this.uploadError = 'Invalid File Selected';
      }
    }
  }
};
</script>
