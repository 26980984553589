<template>
  <div class="notice-modal" id="indicative-rate-modal">
    <div class="notice-header">
      Edit Your Priori Rate
    </div>

    <div class="notice-regular-text">
      <div class="row tight-columns bottom-30">
        <div class="col-md-10 col-md-offset-1">
          This is the indicative rate that will be displayed to prospective clients on your Priori profile. Enter either the Client Total or Your Rate and the other values will be calculated for you. The client total is the sum of your rate plus the Priori Management Fee. <span class="semibold-weight">The standard Priori Management Fee is {{ managementFeePercent }}%.</span>
        </div>
      </div>

      <div class="row tight-columns">
        <div class="col-md-10 col-md-offset-1">
          <rate-form
            :rate="rate"
            :management-fee-multiplier="managementFeeMultiplier"
            :on-cancel="onCancel"
            :on-submit="onSaveRate">
          </rate-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigService from 'vue-app/shared/services/config-service';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';
import RateForm from 'vue-app/marketplace/lawyer/shared/rate-form.vue';
import { round } from 'lodash';

export default {
  name: 'IndicativeRateModal',

  components: {
    RateForm
  },

  mixins: [
    modalToggle
  ],

  props: {
    lawyerRateCents: {
      type: Number,
      required: true
    },

    onCancel: {
      type: Function,
      required: true
    },

    onSaveRate: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      managementFeePercent: ConfigService.defaultManagementFeePercent
    };
  },

  computed: {
    managementFeeMultiplier() {
      return 1.0 + (this.managementFeePercent / 100);
    },

    clientRateCents() {
      return round(this.lawyerRateCents * this.managementFeeMultiplier);
    },

    rate() {
      return {
        lawyerAmount: this.lawyerRateCents,
        lawyerAmountDollars: this.lawyerRateCents / 100,
        clientRate: this.clientRateCents,
        clientRateDollars: this.clientRateCents / 100
      };
    }
  }
};
</script>
