<template>
  <popover :target="target" :placement="placement" :transition="0" :trigger="trigger" :custom-class="customClassWithDefault">
    <slot></slot>

    <template #popover>
      <slot name="tooltip"></slot>
    </template>
  </popover>
</template>

<script>
export default {
  name: 'PopoverTooltip',

  props: {
    target: {
      type: String,
      required: false
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    trigger: {
      type: String,
      default: 'hover-focus'
    },

    customClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    customClassWithDefault() {
      return ['tooltip-popover', this.customClass].join(' ');
    }
  }
};
</script>
