<template>
  <div v-if="hasEmploymentRecords">
    <div class="employment-history-list">
      <div class="employment-history-item" v-for="employment in employmentRecordsToDisplay" :key="employment.id">
        <lawyer-employment :employment="employment"></lawyer-employment>
        <hr class="kenny-hr">
      </div>
    </div>

    <div class="top-30" v-if="isExpandable">
      <a href="" class="semibold-weight" @click.prevent="toggleShowAll">View {{ showAll ? 'Less' : 'More' }}</a>
    </div>
  </div>
</template>

<script>
import LawyerEmployment from './lawyer-employment.vue';

export default {
  name: 'LawyerEmploymentHistory',

  components: {
    LawyerEmployment
  },

  props: {
    employmentRecords: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      numberToDisplay: 2,
      showAll: false
    };
  },

  computed: {
    hasEmploymentRecords() {
      return this.employmentRecords.length > 0;
    },

    isExpandable() {
      return this.employmentRecords.length > this.numberToDisplay;
    },

    employmentRecordsToDisplay() {
      if (this.showAll) {
        return this.employmentRecords;
      }
      else {
        return this.employmentRecords.slice(0, this.numberToDisplay);
      }
    }
  },

  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style lang="scss" scoped>
  .employment-history-item:last-child hr {
    display: none;
  }
</style>
