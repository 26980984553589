<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div id="bio-section">
        <div class="bottom-15">
          <span class="tiny-header-text">About</span>
          <a href="" class="darker-gray-text semibold-weight left-5" @click.prevent="onEditProfile('bio')" v-if="!viewingAsClient">(Edit Section / Add Alternative Bio)</a>
        </div>

        <div v-if="hasBioText">
          <span class="simple-text">{{ bioTextToDisplay }}<span v-if="isBioExpandable"> <a href="" @click.prevent="expandBio">+more</a></span></span>
        </div>

        <div v-else>
          &mdash;
        </div>

        <div class="top-15" v-if="hasResume">
          <a :href="resume.url" target="_blank">View Resume</a>
        </div>
      </div>

      <div id="resume-section" v-if="!viewingAsClient">
        <hr class="kenny-hr normal">

        <div class="bottom-15">
          <span class="tiny-header-text right-5">Resume</span>
          <div class="visible-xs"></div>
          <span class="dark-gray-text">(Please upload your resume in PDF format)</span>
        </div>

        <resume-uploader :resume="resume" :on-upload-resume="onUploadResume"></resume-uploader>
      </div>

      <hr class="kenny-hr normal">

      <div id="education-section">
        <div class="bottom-15">
          <span class="tiny-header-text">Education</span>
          <a href="" class="darker-gray-text semibold-weight left-5" @click.prevent="onEditProfile('education-histories')" v-if="!viewingAsClient">(Edit Section)</a>
        </div>

        <div class="list-spacing-5" v-for="school in schools" :key="school.id">
          <span class="semibold-weight">{{ school.name }}, {{ school.degree }}</span> ({{ formattedDate(school.dateAttained) }})
        </div>

        <div v-if="!schools.length">
          &mdash;
        </div>
      </div>

      <div v-if="!viewingAsClient || hasLegalExperience">
        <hr class="kenny-hr normal">

        <div id="experience-section">
          <div class="tiny-header-text bottom-20">
            Notable Experience
          </div>

          <div class="tag-list">
            <div class="tag-list-item gray compact" v-for="experience in notableExperienceTags">
              {{ experience }}
            </div>

            <div class="tag-list-item compact empty" @click.prevent="onEditProfile('experience')" v-if="!viewingAsClient">
              Edit Notable Experience
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResumeUploader from './resume-uploader.vue';
import DateFilter from 'vue-app/shared/lib/date-filter';

export default {
  name: 'LawyerBio',

  components: {
    ResumeUploader
  },

  props: {
    lawyerProfile: {
      type: Object,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      required: true
    },

    onEditProfile: {
      type: Function,
      required: true
    },

    onUploadResume: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showFullBio: false,
      charLimit: 750
    };
  },

  computed: {
    fullBioText() {
      return (this.lawyerProfile.practiceDescription || '').trim();
    },

    hasBioText() {
      return this.fullBioText.length > 0;
    },

    isBioExpandable() {
      return this.fullBioText.length > this.charLimit && !this.showFullBio;
    },

    bioTextToDisplay() {
      return this.showFullBio ? this.fullBioText : `${this.fullBioText.slice(0, this.charLimit)}...`;
    },

    schools() {
      return this.lawyerProfile.schoolsAttributes;
    },

    notableExperienceTags() {
      return this.lawyerProfile.notableExperienceTags;
    },

    hasLegalExperience() {
      return (this.notableExperienceTags || []).length > 0;
    },

    resume() {
      return this.lawyerProfile.resume;
    },

    hasResume() {
      return this.resume && this.resume.url;
    }
  },

  methods: {
    expandBio() {
      this.showFullBio = true;
    },

    formattedDate(date) {
      return DateFilter.year(date);
    }
  }
};
</script>
