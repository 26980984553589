<template>
  <div class="shadowed-box" v-if="hasTestimonials || !viewingAsClient">
    <div class="box-header text-uppercase">
      Testimonials
    </div>

    <div class="box-content symmetrical">
      <div class="row" v-if="hasTestimonials">
        <div v-for="(testimonial, index) in testimomialsToDisplay" :key="testimonial.id">
          <div class="col-md-6 bottom-30">
            <span class="size-text-base semibold-weight">“{{ testimonial.quote() }}”</span>
          </div>

          <div class="clearfix" v-if="(index + 1) % 2 == 0"></div>
        </div>
      </div>

      <div v-if="isExpandable">
        <button type="button" class="pseudo-link-blue semibold-weight" @click="toggleShowAll">View {{ showAll ? 'Less' : 'More' }}</button>
      </div>

      <div class="size-text-16px gray-text top-10 bottom-10" v-if="!hasTestimonials">
        You have not been reviewed yet. To invite a client to review your legal services outside of Priori, please <a href="/lawyer-app/review-invites">click here</a>.
      </div>
    </div>

    <div class="box-footer text-center" v-if="!viewingAsClient">
      <a href="/lawyer-app/review-invites" class="semibold-weight size-text-13px">Manage Invited Reviews</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LawyerTestimonials',

  props: {
    testimonials: {
      type: Array,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      numberToDisplay: 2,
      showAll: false
    };
  },

  computed: {
    hasTestimonials() {
      return this.testimonials.length > 0;
    },

    isExpandable() {
      return this.testimonials.length > this.numberToDisplay;
    },

    testimomialsToDisplay() {
      return this.showAll ? this.testimonials : this.testimonials.slice(0, this.numberToDisplay);
    }
  },

  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    }
  }
};
</script>
