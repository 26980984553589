<template>
  <div class="employment-card">
    <div class="row bottom-15">
      <div class="col-sm-8">
        <span class="size-text-base semibold-weight right-10">{{ employment.firmName }}</span>
        <span class="dark-gray-text text-nowrap">{{ firmSize }}</span>
      </div>

      <div class="col-sm-4 text-right-not-xs">
        <span class="semibold-weight dark-gray-text">{{ dateRange }}</span>
      </div>
    </div>

    <div class="small-header-text">
      {{ employment.title }}
    </div>

    <div class="top-5" v-if="hasPositionDescription">
      <div v-if="positionDescription.length >= 200 && !showFullDescription">
        <span class="simple-text">{{ truncatedDescription }}... <a href="" @click.prevent="toggleDescription()">+more</a></span>
      </div>

      <div v-if="positionDescription.length < 200 || showFullDescription">
        <span class="simple-text">{{ positionDescription }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'LawyerEmployment',

  props: {
    employment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showFullDescription: false
    };
  },

  computed: {
    firmSize() {
      if (this.employment.firmSize) {
        return this.employment.firmSize.replace('employee', 'Employee');
      }
      else {
        return '';
      }
    },

    fromDate() {
      return DateFilter.year(this.employment.fromDate);
    },

    toDate() {
      return DateFilter.year(this.employment.toDate);
    },

    dateRange() {
      if (this.employment.currentPosition) {
        return this.fromDate ? this.fromDate + ' - Present' : 'Current Position';
      }
      else {
        return this.toDate ? this.fromDate + ' - ' + this.toDate : this.fromDate;
      }
    },

    positionDescription() {
      return this.employment.positionDescription;
    },

    hasPositionDescription() {
      return this.positionDescription && this.positionDescription.length > 0;
    },

    truncatedDescription() {
      return this.employment.positionDescription.substring(0, 200);
    }
  },

  methods: {
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    }
  }
};
</script>
