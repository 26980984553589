import CurrencyFormatter from '../misc/currency-formatter';

class CurrencyFilter {
  filter(value, denomination, notation) {
    if (!value && value !== 0) { return ''; }

    if (notation === 'compact') {
      return CurrencyFormatter.compact(denomination).format(value);
    }
    else if (notation === 'onlyDollars') {
      return CurrencyFormatter.onlyDollars().format(value);
    }
    else {
      return CurrencyFormatter.standard(denomination).format(value);
    }
  }
}

const instance = new CurrencyFilter();

export default instance;
