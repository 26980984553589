import Vue from 'vue';

import { isFunction } from 'lodash';

class ModalManager {
  constructor() {
    this.openCallbacks  = {};
    this.closeCallbacks = {};

    this._state = Vue.observable({
      openModal: null
    });
  }

  open(name, data) {
    this._state.openModal = name;

    const callback = this.openCallbacks[name];
    if (isFunction(callback)) { callback(data); }
  }

  close(name) {
    if (this._state.openModal !== name) { return; }
    this._state.openModal = null;

    const callback = this.closeCallbacks[name];
    if (isFunction(callback)) { callback(); }
  }

  isOpen(name) {
    return this._state.openModal === name;
  }

  onOpen(name, callback) {
    this.openCallbacks[name] = callback;
  }

  onClose(name, callback) {
    this.closeCallbacks[name] = callback;
  }
}

const instance = new ModalManager();

export default instance;
