<template>
  <div class="shadowed-box">
    <div class="tab-titles">
      <button type="button" :class="['tab-title text-ellipsis', { 'selected-tab': isTabSelected(tab.name) }]" @click="selectTab(tab.name)" v-for="tab in tabs" :key="tab.name">
        {{ tab.title }}
      </button>
    </div>

    <div class="tab-content" v-for="tab in tabs" :key="tab.name">
      <div :id="`${tabGroupId}-${tab.name}`" v-show="isTabSelected(tab.name)">
        <slot :name="tab.name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'TabGroup',

  props: {
    tabs: {
      type: Array, // expect array of objects with name/title keys
      required: true
    },

    initialTabName: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      selectedTabName: this.initialTabName || this.tabs[0].name
    };
  },

  computed: {
    tabGroupId() {
      return uniqueId('tabgroup-');
    }
  },

  methods: {
    isTabSelected(tabName) {
      return this.selectedTabName === tabName;
    },

    selectTab(tabName) {
      if (this.isTabSelected(tabName)) { return; }
      this.selectedTabName = tabName;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .tab-titles {
    @media (min-width: 768px) {
      display: flex;
    }

    .tab-title {
      width: 100%;
      flex: 1 0 0%;
      padding: 20px 5px;
      height: 60px;
      border: 0;
      border-bottom: 1px solid $k-light-gray;
      background: transparent;
      color: $k-dark-gray;
      font-size: 11px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      &:first-child {
        border-top-left-radius: $border-radius-large;
        border-top-right-radius: $border-radius-large;
      }

      @media (min-width: 768px) {
        &:first-child {
          border-top-right-radius: 0;
        }

        &:last-child {
          border-top-right-radius: $border-radius-large;
        }
      }

      &:hover {
        background-color: $bg-color;
        color: $k-darker-gray;
      }

      &.selected-tab {
        padding-bottom: 17px;
        border-bottom: 3px solid $k-blue;
        color: $k-darker-gray;
      }
    }
  }
</style>
