<template>
  <div>
    <tab-group :tabs="tabs" ref="tagGroup">
      <template #employment>
        <div id="employment-history-section">
          <div class="box-content symmetrical">
            <lawyer-employment-history
              :employment-records="employmentRecords"
              v-if="hasEmploymentRecords">
            </lawyer-employment-history>

            <div class="size-text-16px gray-text top-10 bottom-10" v-else>
              <span v-if="viewingAsClient">Employment history not available.</span>
              <span v-else>Click Edit Section to add employment history.</span>
            </div>
          </div>

          <div class="box-footer text-center" v-if="!viewingAsClient">
            <button type="button" class="pseudo-link-blue semibold-weight size-text-13px" @click="onEditProfile('employment-histories')">Edit Section</button>
          </div>
        </div>
      </template>

      <template #practiceAreas>
        <div id="practice-area-section">
          <div class="box-content symmetrical">
            <tag-list
              :listables="practiceAreas"
              color-class="blue"
              v-if="hasPracticeAreas">
            </tag-list>

            <div class="size-text-16px gray-text top-10 bottom-10" v-else>
              <span v-if="viewingAsClient">Practice areas not available.</span>
              <span v-else>Click Edit Section to add practice areas.</span>
            </div>
          </div>

          <div class="box-footer text-center" v-if="!viewingAsClient">
            <button type="button" class="pseudo-link-blue semibold-weight size-text-13px" @click="onEditProfile('practice-areas')">Edit Section</button>
          </div>

          <div class="message-note semibold-weight" v-if="viewingAsClient && !currentUserIsLawyer">
            These are the primary practice areas for {{ lawyer.fullName }}. For more information about {{ lawyer.firstName }}&rsquo;s experience, feel free to <a href="" class="dark-gray-link" @click.prevent="askQuestion">send a message</a>.
          </div>
        </div>
      </template>

      <template #industries v-if="!viewingAsClient || hasIndustries">
        <div id="industries-section">
          <div class="box-content symmetrical">
            <tag-list
              :listables="industries"
              value-key="label"
              color-class="black"
              v-if="hasIndustries">
            </tag-list>

            <div class="size-text-16px gray-text top-10 bottom-10" v-else>
              Click Edit Section to add industries.
            </div>
          </div>

          <div class="box-footer text-center" v-if="!viewingAsClient">
            <button type="button" class="pseudo-link-blue semibold-weight size-text-13px" @click="onEditProfile('industries')">Edit Section</button>
          </div>

          <div class="message-note semibold-weight" v-if="viewingAsClient && !currentUserIsLawyer">
            These are the primary industries served by {{ lawyer.fullName }}. For more information about {{ lawyer.firstName }}&rsquo;s experience, feel free to <a href="" class="dark-gray-link" @click.prevent="askQuestion">send a message</a>.
          </div>
        </div>
      </template>

      <template #representativeClients v-if="!viewingAsClient || hasRepresentativeClients">
        <div id="representative-clients-section">
          <div class="box-content symmetrical">
            <tag-list
              :listables="representativeClients"
              color-class="green"
              v-if="hasRepresentativeClients">
            </tag-list>

            <div class="size-text-16px gray-text top-10 bottom-10" v-else>
              Click Edit Section to add representative clients.
            </div>
          </div>

          <div class="box-footer text-center" v-if="!viewingAsClient">
            <button type="button" class="pseudo-link-blue semibold-weight size-text-13px" @click="onEditProfile('representative-clients')">Edit Section</button>
          </div>

          <div class="message-note semibold-weight" v-if="viewingAsClient && !currentUserIsLawyer">
            These are the primary representative clients for {{ lawyer.fullName }}. For more information about these or other representative clients, feel free to <a href="" class="dark-gray-link" @click.prevent="askQuestion">message {{ lawyer.firstName }}</a>.
          </div>
        </div>
      </template>
    </tab-group>

    <send-message-modal ref="messageModal" :recipients="[lawyer]"></send-message-modal>
  </div>
</template>

<script>
import TabGroup from 'src/vue-app/marketplace/shared/tab-group.vue';
import TagList from 'src/vue-app/marketplace/shared/tag-list.vue';
import SendMessageModal from 'src/vue-app/shared/components/send-message-modal.vue';
import LawyerEmploymentHistory from './lawyer-employment-history.vue';

export default {
  name: 'LawyerExperience',

  components: {
    TabGroup,
    TagList,
    SendMessageModal,
    LawyerEmploymentHistory
  },

  props: {
    lawyerProfile: {
      type: Object,
      required: true
    },

    lawyer: {
      type: Object,
      required: true
    },

    viewingAsClient: {
      type: Boolean,
      required: true
    },

    currentUserIsLawyer: {
      type: Boolean,
      default: false
    },

    onEditProfile: {
      type: Function,
      required: true
    }
  },

  computed: {
    tabs() {
      const tabs = [
        { name: 'employment', title: 'Employment History' },
        { name: 'practiceAreas', title: 'Practice Areas' }
      ];

      if (!this.viewingAsClient || this.hasIndustries) {
        tabs.push({ name: 'industries', title: 'Industries' });
      }
      if (!this.viewingAsClient || this.hasRepresentativeClients) {
        tabs.push({ name: 'representativeClients', title: 'Representative Clients' });
      }

      return tabs;
    },

    employmentRecords() {
      return this.lawyerProfile.employmentHistoriesAttributes || [];
    },

    hasEmploymentRecords() {
      return this.employmentRecords.length > 0;
    },

    practiceAreas() {
      return this.lawyerProfile.lawyersPracticeAreasAttributes || [];
    },

    hasPracticeAreas() {
      return this.practiceAreas.length > 0;
    },

    industries() {
      return this.lawyerProfile.lawyerIndustriesAttributes || [];
    },

    hasIndustries() {
      return this.industries.length > 0;
    },

    representativeClients() {
      return this.lawyerProfile.representativeClientsAttributes || [];
    },

    hasRepresentativeClients() {
      return this.representativeClients.length > 0;
    }
  },

  watch: {
    viewingAsClient() {
      this.$refs.tagGroup.selectTab(this.tabs[0].name);
    }
  },

  methods: {
    askQuestion() {
      this.$refs.messageModal.openModal();
    }
  }
};
</script>
