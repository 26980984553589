<template>
  <span class="inline-help pr-error" v-if="errors.length">{{ displayErrors }}</span>
</template>

<script>
export default {
  name: 'ErrorMessage',

  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    displayErrors() {
      return this.errors.join(', ');
    }
  }
};
</script>
